import styled from 'styled-components'

import { getAvatarBackgroundColor } from '@/features/profile/utils/getAvatarBackgroundColor'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const ProfileAvatarContainer = styled.div<{
  size: string
  userId?: string
  iconSize: string
}>(
  ({ iconSize, size, theme, userId = '0' }) => `
  align-items: center;
  background: ${getAvatarBackgroundColor(userId, theme.colors)};
  border-radius: ${theme.borderRadius.rounded};
  display: flex;
  height: ${size};
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${size};

  & > svg {
    height: ${iconSize};
    width: auto;
  }
`
)

export const ReviewList = styled(Flex)(
  ({ theme }) => `
  *:nth-child(n+3) {
    display: none;
  }

  ${MEDIA_QUERIES(theme).medium} {
    *:nth-child(3) {
      display: flex; 
    }
  }
`
)

// eslint-disable-next-line @kijiji/prefer-link-custom
export const SellerNameLink = styled.a`
  text-decoration: none;
  color: inherit;
`
