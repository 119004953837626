import CloseCircleIcon from '@kijiji/icons/src/icons/CloseCircle'
import SearchIcon from '@kijiji/icons/src/icons/Search'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import {
  FC,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { FloatingLabel } from '@/ui/atoms/floating-label'
import { onEnterPress } from '@/ui/helpers/keyPress'
import { FormControl } from '@/ui/molecules/form-control'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import {
  FeaturedElementImage,
  InputWrapper,
  LeftIconWrapper,
  RightIconWrapper,
  SearchBarTextInput,
  SearchBarWrapper,
  SearchList,
  SearchListButton,
  SearchListElement,
  SearchResults,
  StyledFormControlInput,
} from './styled'

type FeaturedOption = {
  isFeatured?: true
  featuredKeyword: string
  imgAlt?: string
  imgSrc?: string
  icon?: never
}

type GeneralOption = {
  isFeatured?: false
  featuredKeyword?: never
  imgAlt?: never
  imgSrc?: never
}

export type Option = {
  /**
   * Option Label
   * This label will be shown in the search dropdown
   */
  label: string
  /**
   * Option value
   * This is the value returned onChange
   */
  value?: string
  /**
   * Icon to be shown in the left side of the label
   */
  icon?: ReactElement
  /**
   * Position of the icon in relation to the label
   * @default 'left'
   */
  iconPosition?: 'left' | 'right'
  /**
   * Image src to be shown in the right side of the label
   */
  imgSrc?: string
  /**
   * Alt to describe image.
   * If nothing is passed it will take an empty string
   */
  imgAlt?: string
  /**
   * Featured search option
   * Option appended in the bottom of the search, always visible in the search options.
   */
  isFeatured?: boolean
  /**
   * keyword to be selected as label if feature is clicked
   */
  featuredKeyword?: string
  /**
   * Href if option is supposed to be a link instead of a button
   */
  linkHref?: string
} & (FeaturedOption | GeneralOption)

export type SearchBarProps = {
  /**
   * Label for "clear selection" button
   */
  clearSelectionLabel: string
  /**
   * Icon to be placed in the left of all input options if no specific one was defined
   */
  genericOptionsIcon?: ReactElement | null
  /**
   * Defines if options should have divider in between them
   */
  hasOptionsDivider?: boolean
  /**
   * Defines max height of search options
   */
  maxHeight?: string
  /**
   * Callback to be called when the value of the field changes
   */
  onChange: (changes: UseComboboxStateChange<unknown>) => void
  /**
   * Event triggered when an option is chosen
   */
  onOptionClick?: (option: Option) => void
  /**
   * List of options to be selected on search
   */
  searchOptions: Option[]
  /**
   * Initial input value
   */
  initialInputValue?: string
  /**
   * Defines if the searchBar is used in a group.
   * It will change styling to allow grouping with other fields.
   */
  isGrouped?: boolean
  /**
   * Event to be triggered on keyboard press from the search input
   */
  onInputKeyDown?: (e: KeyboardEvent, option: Option) => Promise<void> | void
  /**
   * Flag to enable the header simplified version
   */
  isHeaderSimplified?: boolean
  /**
   * Flag to have input text bolded on mobile
   */
  isMobileTextBolded?: boolean
  /**
   * Flag to have search bar rounded on mobile
   */
  isMobileSearchBarRounded?: boolean
  /**
   * Flag to hide label text on mobile if focused or has value
   */
  isLabelHiddenOnMobile?: boolean
  /**
   * Flag to make search bar sticky on mobile
   */
  isMobileSearchBarSticky?: boolean
  /**
   * Defines which vertical direction the user is scrolling i
   */
  isScrollingUp?: boolean
  /**
   * Submit button component to be placed in the right of the search input
   */
  submitButton?: React.ReactNode
  /**
   * Z-Index value to be applied to the suggestions dropdown
   */
  zIndexDropDown?: number
} & Omit<CommonInputFieldProps, 'maxLength' | 'helperText' | 'error'>

/**
 * @description Search field component
 */
export const SearchBar: FC<SearchBarProps> = forwardRef<
  HTMLInputElement,
  SearchBarProps
>(
  (
    {
      bottom = '2rem',
      clearSelectionLabel,
      genericOptionsIcon,
      hasOptionsDivider,
      id,
      initialInputValue = '',
      isGrouped,
      label,
      maxHeight = '25.6rem',
      onChange,
      onInputKeyDown,
      onOptionClick,
      searchOptions,
      isHeaderSimplified,
      isMobileTextBolded = false,
      isMobileSearchBarRounded = false,
      isLabelHiddenOnMobile = false,
      isMobileSearchBarSticky = false,
      isScrollingUp = false,
      submitButton,
      zIndexDropDown,
    }: SearchBarProps,
    forwardedRef
  ) => {
    const [hasLoadedInitialValue, setHasLoadedInitialValue] =
      useState<boolean>(false)
    const { colors } = useTheme()
    const [isFocused, setIsFocused] = useState(false)

    const featuredOptions = searchOptions.filter((item) => item.isFeatured)
    const generalOptions = searchOptions.filter((item) => !item.isFeatured)

    const itemToString = (item: Option | null): string => {
      if (item?.isFeatured) return item.featuredKeyword

      return item?.value || item?.label || ''
    }

    const {
      closeMenu,
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      inputValue,
      setInputValue,
      isOpen,
      reset,
      selectItem,
      selectedItem,
    } = useCombobox({
      /** It will guarantee the featured-options will be placed at the bottom of the index list */
      id,
      items: [...generalOptions, ...featuredOptions],
      itemToString,
      initialInputValue,
      onInputValueChange: onChange,
    })

    useEffect(() => {
      if (hasLoadedInitialValue || !initialInputValue) return

      setInputValue(initialInputValue)
      setHasLoadedInitialValue(true)
    }, [hasLoadedInitialValue, initialInputValue])

    const clearSelection = () => {
      setIsFocused(false)
      reset()
    }

    return (
      <SearchBarWrapper
        data-testid="search-bar-wrapper"
        isFocused={isFocused}
        isMobileSearchBarRounded={isMobileSearchBarRounded}
        isMobileSearchBarSticky={isMobileSearchBarSticky}
        isScrollingUp={isScrollingUp}
      >
        <FormControl isFullWidth id={id} bottom={bottom}>
          <StyledFormControlInput
            $isFocused={isFocused}
            $isGrouped={isGrouped}
            isHeaderSimplified={isHeaderSimplified}
            isMobileSearchBarRounded={isMobileSearchBarRounded}
            endAdornment={
              <>
                {selectedItem && (
                  <button
                    aria-label={clearSelectionLabel}
                    onClick={clearSelection}
                    type="button"
                  >
                    <CloseCircleIcon aria-hidden />
                  </button>
                )}
                {isHeaderSimplified ? submitButton : null}
              </>
            }
            startAdornment={
              isHeaderSimplified && !isMobileSearchBarRounded ? null : (
                <SearchIcon aria-hidden />
              )
            }
            inputRef={getInputProps().inputRef}
          >
            <InputWrapper
              isLabelHiddenOnMobile={isLabelHiddenOnMobile}
              isFocused={isFocused}
              hasInputValue={inputValue.length > 0}
            >
              <FloatingLabel
                htmlFor={id}
                isFocused={isFocused || selectedItem || inputValue.length > 0}
                {...getLabelProps()}
              >
                {label}
              </FloatingLabel>

              <SearchBarTextInput
                id={id}
                data-testid={`search-input-${id}`}
                isMobileTextBolded={isMobileTextBolded}
                isMobileSearchBarRounded={isMobileSearchBarRounded}
                {...getInputProps({
                  type: 'search',
                  onBlur: () => setIsFocused(false),
                  onFocus: () => setIsFocused(true),
                  onKeyDown: (event) => {
                    onEnterPress(event, () => {
                      event.preventDefault()
                      // Either have a highlighted option been chosen, or get the input value
                      const item = searchOptions[highlightedIndex] || {
                        label: inputValue,
                        value: inputValue,
                      }
                      onInputKeyDown?.(event, item)

                      // Taking over control over what is written on the input
                      selectItem(item)
                      closeMenu()
                    })
                  },
                  onChange: () => {
                    /**
                     * If the input field changes, the user has started a new search
                     * Therefore, clear the previously selected item
                     */
                    selectItem(null)
                  },
                  ref: forwardedRef,
                })}
              />
            </InputWrapper>
          </StyledFormControlInput>

          <SearchResults zIndex={zIndexDropDown}>
            <SearchList maxHeight={maxHeight} {...getMenuProps()}>
              {isOpen && (
                <>
                  <div>
                    {generalOptions?.map((item, index) => {
                      const elementIcon =
                        item.icon || genericOptionsIcon || null

                      return (
                        <SearchListElement
                          data-testid="search-list-element"
                          hasDivider={hasOptionsDivider}
                          isHighlighted={highlightedIndex === index}
                          key={`${item.value}${index}`}
                          {...getItemProps({ item, index })}
                        >
                          <SearchListButton
                            as={item.linkHref ? 'a' : 'button'}
                            href={item.linkHref}
                            onClick={() => {
                              selectItem(item)
                              onOptionClick?.(item)
                            }}
                            target={item.linkHref ? '_blank' : '_self'}
                          >
                            {elementIcon && item.iconPosition !== 'right' && (
                              <LeftIconWrapper>{elementIcon}</LeftIconWrapper>
                            )}

                            <BodyText color={colors.grey.primary} size="medium">
                              {item.label}
                            </BodyText>

                            {elementIcon && item.iconPosition === 'right' && (
                              <RightIconWrapper>{elementIcon}</RightIconWrapper>
                            )}
                          </SearchListButton>
                        </SearchListElement>
                      )
                    })}
                  </div>

                  {featuredOptions?.map((item, index) => {
                    const featuredIndex = index + generalOptions.length

                    return (
                      <SearchListElement
                        data-testid="highlighted-search-list-element"
                        hasDivider
                        isHighlighted={highlightedIndex === featuredIndex}
                        key={`${item.value}-${featuredIndex}`}
                        {...getItemProps({ item, index: featuredIndex })}
                      >
                        <button
                          type="button"
                          onClick={() => onOptionClick?.(item)}
                        >
                          {item.imgSrc && (
                            <FeaturedElementImage
                              alt={item.imgAlt || ''}
                              src={item.imgSrc}
                            />
                          )}
                          <BodyText color={colors.grey.primary} size="medium">
                            {item.label}
                          </BodyText>
                        </button>
                      </SearchListElement>
                    )
                  })}
                </>
              )}
            </SearchList>
          </SearchResults>
        </FormControl>
      </SearchBarWrapper>
    )
  }
)

SearchBar.displayName = 'SearchBar'
