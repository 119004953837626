import { type FC, type PropsWithChildren, useEffect, useRef, useState } from 'react'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { Logo } from '@/components/shared/global-header/Logo'
import { SearchBar } from '@/components/shared/global-header/search-bar'
import { SearchBarContainer } from '@/components/shared/global-header/search-bar/styled'
import {
  Container,
  GlobalHeaderContainer,
  GlobalHeaderWrapper,
  LogoWrapper,
  Wrapper,
} from '@/components/shared/global-header/styled'
import { Tools } from '@/components/shared/global-header/tools'
import { GlobalMessages } from '@/components/shared/global-messages'
import { usePSABanner } from '@/components/shared/psa-banner/usePSABanner'
import { type PSAKeys } from '@/constants/psaBanner'
import { useClickOutside } from '@/hooks/useClickOutside'
import { RemoteParamKeys, useExperiment } from '@/lib/firebase/hooks'
import { ExperimentValues } from '@/lib/firebase/types'

export type GlobalHeaderProps = PropsWithChildren & {
  /**
   * Will show the search bar in the header. Defaults to false.
   */
  showSearchBar: boolean
  psaCampaign?: PSAKeys
  /**
   * Defines if the navigation should be rendered in the header
   */
  hasNavigation?: boolean
}

export const GlobalHeader: FC<GlobalHeaderProps> = ({
  psaCampaign,
  showSearchBar = false,
  children,
  hasNavigation = false,
}) => {
  const { enabled, group } = useExperiment(RemoteParamKeys.HEADER_SIMPLIFIED_TEST) ?? {}
  const isHeaderSimplified = enabled === true && group === ExperimentValues.GROUP_B

  const [isMobileHeaderExpanded, toggleMobileHeader] = useState<boolean>(false)
  const [isHeaderInViewport, setIsHeaderInViewport] = useState<boolean>(true)
  const globalHeader = useRef<HTMLElement>(null)
  const globalHeaderLogoRef = useRef<HTMLDivElement>(null)

  const { isVisible: isPSAVisible } = usePSABanner(psaCampaign)

  const handleClickOut = () => {
    toggleMobileHeader(false)
  }

  useClickOutside(globalHeader, () => handleClickOut)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Detect when header is no longer in view
        if (!entry.isIntersecting) {
          setIsHeaderInViewport(false)
        } else {
          setIsHeaderInViewport(true)
        }
      },
      { threshold: 0 }
    )

    if (globalHeaderLogoRef.current) {
      observer.observe(globalHeaderLogoRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <GlobalHeaderWrapper>
      <Container
        ref={globalHeader}
        id="global-header"
        hasChildren={!!children}
        isPSABannerVisible={isPSAVisible}
        hasNavigation={hasNavigation}
      >
        <GlobalHeaderContainer>
          <Wrapper isHeaderSimplified={isHeaderSimplified}>
            <LogoWrapper ref={globalHeaderLogoRef} data-testid="global-header-logo">
              <Logo />
            </LogoWrapper>

            <ErrorBoundary fingerprintId="GlobalHeader">
              {showSearchBar && (
                <SearchBarContainer
                  showSearchBar={showSearchBar}
                  data-testid="global-search-bar"
                  isHeaderSimplified={isHeaderSimplified}
                >
                  <SearchBar
                    isMobileHeaderExpanded={isMobileHeaderExpanded}
                    toggleMobileHeader={toggleMobileHeader}
                    isHeaderInViewport={isHeaderInViewport}
                  />
                </SearchBarContainer>
              )}
            </ErrorBoundary>

            <Tools showPostAdCTA={showSearchBar} />
          </Wrapper>

          {children}
        </GlobalHeaderContainer>
      </Container>

      <GlobalMessages />
    </GlobalHeaderWrapper>
  )
}
