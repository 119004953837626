import styled from 'styled-components'

import { StyledTextInput } from '@/ui/atoms/text-input/styled'
import { COMMON_INPUT_STYLE } from '@/ui/constants/commonFormStyle'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { FormControlInput } from '../form-control'

export const SearchBarTextInput = styled(StyledTextInput)<{
  isMobileTextBolded: boolean
  isMobileSearchBarRounded: boolean
}>(
  ({ theme, isMobileTextBolded, isMobileSearchBarRounded }) => `
  ${isMobileTextBolded ? `font-weight: ${theme.fontWeight.semiBold};` : ''}
  ${isMobileSearchBarRounded ? 'padding: 1rem 1.6rem;' : ''}
      
  ${MEDIA_QUERIES(theme).medium} {
    ${COMMON_INPUT_STYLE(theme)};
    font-weight: ${theme.fontWeight.regular};
  }


`
)

type SearchBarWrapperProps = {
  isFocused?: boolean
  isMobileSearchBarRounded?: boolean
  isMobileSearchBarSticky?: boolean
  isScrollingUp?: boolean
}

type StyledFormControlInputProps = {
  $isFocused?: boolean
  $isGrouped?: boolean
  isHeaderSimplified?: boolean
  isMobileSearchBarRounded?: boolean
}

export const StyledFormControlInput = styled(
  FormControlInput
)<StyledFormControlInputProps>(
  ({
    theme,
    $isFocused,
    $isGrouped,
    isHeaderSimplified,
    isMobileSearchBarRounded,
  }) => `
  ${$isFocused || $isGrouped ? `border-color: transparent;` : ''}
  ${
    isHeaderSimplified
      ? `
    gap: 1rem;
    padding-right: 0;
    `
      : ''
  }
  ${isMobileSearchBarRounded ? `border-radius: 2.5rem;` : ''}

  & button {
    ${isMobileSearchBarRounded ? 'display: none;' : ''}
  }

  ${MEDIA_QUERIES(theme).medium} {
    border-radius: 0rem;
    & button {
      display: block;
    }
  }
  
`
)

export const SearchBarWrapper = styled.div<SearchBarWrapperProps>(
  ({
    isFocused,
    isMobileSearchBarRounded,
    isMobileSearchBarSticky,
    isScrollingUp,
    theme,
  }) => `
  & > div {
    border: .1rem solid transparent;
  }

  ${
    isMobileSearchBarSticky
      ? `
      background-color: ${theme.colors.white};
      box-shadow: ${isScrollingUp ? 'none' : `${theme.boxShadow['shadow-4']}`};
      left: 0;
      padding: 1.6rem 1.6rem 0.8rem 1.6rem;
      position: fixed;
      top: 0;
      width: 100%;
    `
      : ''
  }

  ${
    isFocused
      ? `
    & > div {
      border-color: ${theme.colors.blue.primary};
      border-radius: ${isMobileSearchBarRounded ? `${theme.borderRadius.xLarge}` : `${theme.borderRadius.small}`};
      box-shadow: 0 0 .8rem rgba(38, 129, 219, 0.5);
      overflow: hidden;
    }

    & > div > div[aria-expanded="true"] > div {
      border-radius: ${theme.borderRadius.small};
      border-bottom-color: ${theme.colors.blue.primary};
    }
    `
      : ''
  }

  ${MEDIA_QUERIES(theme).medium} {
    ${
      isFocused
        ? `
      & > div {
      border-radius: ${theme.borderRadius.small}; 
      }
      `
        : ''
    }
  background-color: unset;
  box-shadow: none;
  position: relative;
  padding: 0;
}
`
)

type SearchResultsContainerProps = {
  zIndex?: number
}

export const SearchResults = styled.div<SearchResultsContainerProps>(
  ({ theme, zIndex }) => `
  background-color: ${theme.colors.white};
  z-index: ${zIndex ?? 'auto'};
`
)

type SearchListProps = {
  maxHeight: string
}

export const SearchList = styled.ul<SearchListProps>(
  ({ maxHeight, theme }) => `
  list-style: none;
  padding: 0;

  & > div {
  max-height: ${maxHeight};
  overflow-y: auto;

    &: first-of-type {
    border-top: .1rem solid ${theme.colors.blue.primary};
  }
}
`
)

type SearchListElementProps = {
  isHighlighted: boolean
  hasDivider?: boolean
}

export const SearchListElement = styled.li<SearchListElementProps>(
  ({ theme, isHighlighted, hasDivider }) => `
  background-color: ${
    isHighlighted ? theme.colors.grey.light4 : theme.colors.white
  };
  border-top: ${hasDivider ? `.1rem solid ${theme.colors.grey.light4}` : 'none'};
  cursor: pointer;
  
  & > button,
  & > a {
  display: flex;
  padding: 1.2rem ${theme.spacing.default};
  text-decoration: none;
  width: 100%;
}
`
)

export const LeftIconWrapper = styled.span(
  ({ theme }) => `
  margin-right: 2rem;

  & svg {
  flex-shrink: 0;
  height: auto;
  width: ${theme.spacing.large};
}
`
)

export const RightIconWrapper = styled.span(
  ({ theme }) => `
  height: ${theme.spacing.default};
  margin-left: ${theme.spacing.defaultSmall};

  & svg {
  height: ${theme.spacing.default};
  width: 7.8rem;
}
`
)

export const InputWrapper = styled.div<{
  isLabelHiddenOnMobile?: boolean
  isFocused?: boolean
  hasInputValue?: boolean
}>(
  ({ theme, isLabelHiddenOnMobile = false, isFocused, hasInputValue }) => `
  ${isLabelHiddenOnMobile ? 'padding-right: 2.5rem;' : ''}
  position: relative;
  width: 100%;

  & > label {
    ${
      isFocused || hasInputValue
        ? `display: ${isLabelHiddenOnMobile ? 'none' : 'block'};`
        : ''
    }
}

  ${MEDIA_QUERIES(theme).medium} {
  padding-right: 0;
      & > label {
    display: block;
  }
}
  }
`
)

export const FeaturedElementImage = styled.img(
  ({ theme }) => `
  height: 2rem;
  margin-right: ${theme.spacing.defaultSmall};
  width: auto;
`
)

/**
 * Necessary to access styled "as" prop
 */
export const SearchListButton = styled.button`
  align-items: center;
  display: flex;
`
