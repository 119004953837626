import {
  isAnyCarsVehiclesCategory,
  isAnyJobsCategory,
  isAnyRealEstateCategory,
} from '@kijiji/category'
import { type TFunction } from 'i18next'
import { Trans } from 'next-i18next'
import { type ReactNode } from 'react'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'

const safetyLink = (t: TFunction, descriptionKey: string) => (
  <Trans
    i18nKey={descriptionKey}
    components={[
      <LinkCustom
        hasUnderline
        href={t('routes:external.safety_disclaimer.href')}
        key="r2s-success-description"
        noChildren
        target="_blank"
        variant="secondary"
      />,
    ]}
  />
)

/**
 * Returns the success message title & description based on the category of the listing and the seller profile
 *
 * Uses TRANSLATION_KEYS.R2S & TRANSLATION_KEYS.ROUTES on returned translations
 */
export const getR2SSuccessMessage = (
  t: TFunction,
  categoryId: number,
  isProfessionalSeller?: boolean
): {
  title: string
  description: string | ReactNode
} => {
  const baseTitleIntl = `r2s:message_form.success.title`
  const baseDescriptionIntl = `r2s:message_form.success.description`

  const messageTitle = isProfessionalSeller
    ? t(`${baseTitleIntl}.professional`)
    : t(`${baseTitleIntl}.generic`)

  const response = { title: messageTitle }

  /** The description is defined per category and profile type */
  if (isAnyCarsVehiclesCategory(categoryId)) {
    const intlSuffix = isProfessionalSeller ? 'professional.vehicles' : 'vehicles'
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.${intlSuffix}`) }
  }

  /** If the listing is from a professional seller the description will be the same */
  if (isProfessionalSeller) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.professional`) }
  }

  /**
   * When the listing is not from a professional seller, it will vary depending on the category
   */
  if (isAnyRealEstateCategory(categoryId)) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.real_estate`) }
  }

  if (isAnyJobsCategory(categoryId)) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.jobs`) }
  }

  return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.generic`) }
}
