import { type SearchCategory, useGetSearchCategoriesQuery } from '@kijiji/generated/graphql-types'
import SearchIcon from '@kijiji/icons/src/icons/Search'
import { type UseComboboxState } from 'downshift'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import {
  type Dispatch,
  type FC,
  type KeyboardEvent,
  type MouseEvent,
  type SetStateAction,
  useEffect,
  useState,
} from 'react'

import { SearchLocation } from '@/components/shared/global-header/search-bar/SearchLocation'
import {
  ButtonWrapper,
  Divider,
  MobileButtonContainer,
  SearchButton,
  SearchFieldContainer,
  SearchFieldWrapper,
} from '@/components/shared/global-header/search-bar/styled'
import { useSearchSuggestions } from '@/components/shared/global-header/search-bar/suggestions/useSearchSuggestions'
import { ALL_CATEGORIES_ID_NUM, DEFAULT_CATEGORY } from '@/constants/category'
import { DEBOUNCE_MS } from '@/constants/others'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { type SearchSuggestion, trackSearchSubmit } from '@/domain/headerSearchBar'
import { onEnterPress } from '@/domain/keyPress'
import { isDominantCategorySearch } from '@/domain/srp/isDominantCategorySearch'
import { useGetSearchCategory } from '@/hooks/category/useGetSearchCategory'
import { useGetSearchKeyword } from '@/hooks/keywords/useGetSearchKeywords'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useSearchLoadingState } from '@/hooks/srp'
import { useSearchActions } from '@/hooks/srp/useSearchActions'
import { useDebounce } from '@/hooks/useDebounce'
import { useLocale } from '@/hooks/useLocale'
import { useScrollDirection } from '@/hooks/useScrollDirection'
import { RemoteParamKeys, useExperiment, useToggle } from '@/lib/firebase/hooks'
import { ExperimentValues } from '@/lib/firebase/types'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Button } from '@/ui/atoms/button'

const UISearchBar = dynamic(
  () => import('@/ui/molecules/search-bar').then((mod) => mod.SearchBar),
  { ssr: false }
)

const CategoryDropdown = dynamic(
  () => import('./category-dropdown').then((mod) => mod.CategoryDropdown),
  { ssr: false }
)

export type SearchBarProps = {
  /**
   * Specifies whether the header is expanded in mobile view
   * @default false
   */
  isMobileHeaderExpanded: boolean
  /**
   * Toggles the mobile header expanded state
   */
  toggleMobileHeader: Dispatch<SetStateAction<boolean>>
  /**
   * Specifies whether the header is on screen or not
   */
  isHeaderInViewport?: boolean
}

export const SearchBar: FC<SearchBarProps> = ({
  isMobileHeaderExpanded,
  toggleMobileHeader,
  isHeaderInViewport = true,
}) => {
  const { enabled, group, trackingKey } =
    useExperiment(RemoteParamKeys.HEADER_SIMPLIFIED_TEST) ?? {}
  const isHeaderSimplified = enabled === true && group === ExperimentValues.GROUP_B
  const isSrpControlsMwebToggleEnabled =
    useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)?.enabled ?? false

  // header-simplified-kjca1625
  const gaTagId = `header-simplified-${trackingKey}`
  const gaVariationId =
    group === ExperimentValues.GROUP_A ? 0 : group === ExperimentValues.GROUP_B ? 1 : -1

  const { loadingResults } = useSearchLoadingState()
  const { handleNewSearch } = useSearchActions({ newSearch: true })
  const { asPath, pathname } = useRouter()
  const isSRPPage = pathname.startsWith('/srp/')

  const { apiLocale, cookieLocale } = useLocale()
  const { t } = useTranslation('global_header')

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [isMobileSearchBarSticky, setIsMobileSearchBarSticky] = useState<boolean>(false)

  const { keyword, updateSearchKeyword } = useGetSearchKeyword()
  const { category, updateSearchCategory } = useGetSearchCategory()
  const { location } = useGetLocation()
  const isScrollingUp = useScrollDirection()

  /**
   * Category & Keyword selected before form is submitted.
   * It will only update the global category & Keywords once the search has been submitted.
   * */
  const [selectedCategory, setSelectedCategory] = useState<SearchCategory>(category)
  const [localKeyword, setLocalKeyword] = useState<string>(keyword)

  /**
   * The appended category is a selected search category that is not in the L1 list.
   * We added this as a separate value to be easier to add and remove it from the dropdown list.
   */
  const [appendedCategory, setAppendedCategory] = useState<SearchCategory[]>([])
  const [categoryList, setCategoryList] = useState<SearchCategory[]>([DEFAULT_CATEGORY[apiLocale]])

  /** Debounce for the search-bar typing */
  const debounce = useDebounce(DEBOUNCE_MS)

  const { suggestions, getSuggestions } = useSearchSuggestions({ categoryId: selectedCategory.id })

  /**
   * Listen to the results update to stop the search bar loading
   */
  useEffect(() => {
    if (!loadingResults && loadingSearch) {
      setLoadingSearch(false)
    }
  }, [loadingResults, loadingSearch])

  useEffect(() => {
    // When initiatedSearch changes from true to false, collapse the mobile header
    if (!loadingSearch) {
      toggleMobileHeader(false)
    }
  }, [loadingSearch, toggleMobileHeader])

  useEffect(() => {
    /**
     * In case the user changed the global category from a different component,
     * the header should automatically show the new selected category
     * */

    /** Updates the category dropdown & appends the new category if is not a part of the list */
    handleCategoryUpdate(category)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.id])

  useEffect(() => {
    setIsMobileSearchBarSticky(!isHeaderInViewport && isSRPPage && isSrpControlsMwebToggleEnabled)
  }, [isHeaderInViewport, isSRPPage, isSrpControlsMwebToggleEnabled])

  /** Fetch L1 Categories for the dropdown */
  useGetSearchCategoriesQuery({
    fetchPolicy: 'cache-first',
    ssr: false,
    variables: { locale: cookieLocale },
    onCompleted: ({ searchCategories }) => {
      const list =
        searchCategories?.reduce((acc: SearchCategory[], curr) => {
          if (curr?.id === ALL_CATEGORIES_ID_NUM) {
            return [...acc, DEFAULT_CATEGORY[apiLocale]]
          }

          if (!curr || !curr.localizedName) return acc

          return [
            ...acc,
            { id: curr.id, localizedName: curr.localizedName, parentId: curr.parentId },
          ]
        }, []) || []

      setCategoryList(list)
    },
  })

  /** Checks and updates the appended category option if the category is not already in the dropdown */
  const handleCategoryUpdate = (newCategory: SearchCategory) => {
    const isCategoryOnList = categoryList.find((item) => item.id === newCategory.id)
    isCategoryOnList ? setAppendedCategory([]) : setAppendedCategory([newCategory])

    /**
     * Should only set category from cache when search is submitted - not on change
     */
    setSelectedCategory(newCategory)
  }

  /** The handleChange function will be trigger on every key press */
  const handleOnChange = (updatedValue: unknown) => {
    /**
     * This handleOnChange function doesn't take an "event" as a parameter.
     * The returned value is defined by the UseComboboxState interface.
     * The type argument defines the selectedItem type.
     */
    const value = updatedValue as UseComboboxState<SearchSuggestion | null>
    const { inputValue, selectedItem } = value

    /**
     * There are two types of onChange events: InputChange and OptionClick
     * InputChange is identified by the selectedItem property being null,
     * otherwise, it is OptionClick.
     */
    if (!selectedItem || !selectedItem.value) {
      setLocalKeyword(inputValue)
      inputValue.length ? debounce(() => getSuggestions(inputValue))() : getSuggestions('')
      return
    }

    setLocalKeyword(selectedItem.value)
  }

  const handleOptionClick = (selectedOption: SearchSuggestion) => {
    if (!selectedOption.value) {
      setLocalKeyword('')
      return
    }

    const newKeyword = selectedOption.value

    if (newKeyword !== localKeyword) {
      setLocalKeyword(newKeyword)
    }

    /**
     * Check if category from option is on L1 list
     * If is not present, then it should add to the appended Category
     */
    if (selectedOption.category?.id) {
      const localizedName = selectedOption.category.localizedName

      const newCategory: SearchCategory = {
        id: selectedOption.category.id,
        name: { en_CA: localizedName, fr_CA: localizedName },
        localizedName: localizedName,
      }

      handleCategoryUpdate(newCategory)
      handleSearchSubmit({
        searchCategory: newCategory,
        searchKeyword: newKeyword,
        selectedSuggestion: selectedOption,
      })
    }

    /**
     * Trigger on submit if it is not a category-option
     * */
    if (!selectedOption.category) {
      handleSearchSubmit({
        searchCategory: selectedCategory,
        searchKeyword: selectedOption.value ?? '',
        selectedSuggestion: selectedOption,
      })
    }
  }

  /**
   * Submits a search either with the press of the submit button or when a category option is chosen
   * the selected category is passed as a parameters to allow "handleOptionClick" to trigger it before the state has been updated
   */
  const handleSearchSubmit = async ({
    event,
    searchCategory,
    searchKeyword,
    selectedSuggestion,
  }: {
    event?: MouseEvent | KeyboardEvent
    searchCategory: SearchCategory
    searchKeyword: string
    selectedSuggestion?: SearchSuggestion | null
  }) => {
    event?.preventDefault()
    setLoadingSearch(true)

    let searchCategoryId = searchCategory.id
    /** Update category from cache */
    if (category.id !== searchCategoryId) {
      updateSearchCategory(searchCategory)
    } else if (isDominantCategorySearch(asPath)) {
      /**
       * Reset category ID if previous search was dominant category, so user
       * isn't stuck in a category they didn't choose. The category will
       * automatically be updated in the dropdown.
       */
      searchCategoryId = DEFAULT_CATEGORY[apiLocale].id
    }

    /** Update keyword from cache */
    if (keyword !== searchKeyword) {
      updateSearchKeyword(searchKeyword)
    }

    /** Track the search attempt type and meta-data */
    if (searchKeyword) {
      trackSearchSubmit({
        keyword: searchKeyword,
        selectedSuggestion,
        options: {
          customParameters: {
            session_level_test_group: `${gaTagId}=${gaVariationId}`,
          },
        },
      })
    }

    handleNewSearch(searchCategoryId, searchKeyword, location)

    collapseMobileHeader()
    return
  }

  const onSearchFocus = () => {
    toggleMobileHeader(true)
    trackEvent({
      action: GA_EVENT.SearchBegin,
      customParameters: {
        session_level_test_group: `${gaTagId}=${gaVariationId}`,
      },
    })
  }

  const collapseMobileHeader = () => toggleMobileHeader(false)

  const handleSubmitOnKeyPress = (event: KeyboardEvent, option: SearchSuggestion) => {
    onEnterPress(event, () => {
      /**
       * Retrieves focus from search bar
       */
      const inputElement = event.currentTarget as HTMLInputElement
      inputElement.blur()

      /**
       * Updates the option
       * If it is a category-option it will automatically submit the search
       */
      handleOptionClick(option)

      /**
       * Trigger on submit if it is not a category-option,
       * since it won't automatically submit with handleOptionClick
       * */
      if (!option?.category)
        handleSearchSubmit({
          searchCategory: selectedCategory,
          searchKeyword: option.value ?? '',
          selectedSuggestion: option,
        })
    })
  }

  const categoryDropdownList = [...categoryList, ...appendedCategory]

  return (
    <>
      <SearchFieldContainer
        data-testid="global-header-search-bar"
        isHeaderSimplified={isHeaderSimplified}
      >
        <SearchFieldWrapper onFocus={onSearchFocus}>
          <UISearchBar
            bottom="0"
            clearSelectionLabel={t('search_bar.clear_label')}
            id="global-header-search-bar"
            initialInputValue={localKeyword}
            label={t('search_bar.label')}
            onChange={handleOnChange}
            onInputKeyDown={handleSubmitOnKeyPress}
            onOptionClick={handleOptionClick}
            searchOptions={localKeyword ? suggestions : []}
            isHeaderSimplified={isHeaderSimplified}
            isMobileTextBolded={isSrpControlsMwebToggleEnabled}
            isMobileSearchBarRounded={isSrpControlsMwebToggleEnabled}
            isLabelHiddenOnMobile={isSrpControlsMwebToggleEnabled}
            isMobileSearchBarSticky={isMobileSearchBarSticky}
            isScrollingUp={isScrollingUp}
            submitButton={
              <div data-testid="global-header-desktop-button">
                <SearchButton
                  data-testid="header-button-submit-icon"
                  isLoading={false}
                  loadingLabel=""
                  onClick={(event) =>
                    handleSearchSubmit({
                      searchCategory: selectedCategory,
                      searchKeyword: localKeyword,
                      event,
                    })
                  }
                  type="submit"
                  variant="secondary"
                  aria-label={t('search_bar.search')}
                >
                  <SearchIcon aria-hidden />
                </SearchButton>
              </div>
            }
            zIndexDropDown={zIndexRegistry.DROPDOWN}
          />
        </SearchFieldWrapper>

        {isHeaderSimplified ? null : (
          <>
            <Divider />

            <CategoryDropdown
              categories={categoryDropdownList}
              selectedCategory={selectedCategory.id}
              handleChangeCategory={handleCategoryUpdate}
              data-testid="global-header-categories"
              isMobileHeaderExpanded={isMobileHeaderExpanded}
            />
          </>
        )}

        {isHeaderSimplified ? null : (
          <ButtonWrapper data-testid="global-header-desktop-button">
            <Button
              data-testid="header-button-submit"
              aria-label={t('search_bar.search')}
              isLoading={loadingSearch}
              loadingLabel=""
              onClick={(event) =>
                handleSearchSubmit({
                  searchCategory: selectedCategory,
                  searchKeyword: localKeyword,
                  event,
                })
              }
              type="submit"
              variant="secondary"
            >
              {t('search_bar.search')}
            </Button>
          </ButtonWrapper>
        )}
      </SearchFieldContainer>

      {isHeaderSimplified ? null : <SearchLocation id="set-search-range-header" />}

      {isHeaderSimplified ? null : (
        <MobileButtonContainer
          isMobileHeaderExpanded={isMobileHeaderExpanded}
          data-testid="global-header-mobile-button-footer"
        >
          <Button
            isFullWidth
            onClick={(event) =>
              handleSearchSubmit({
                event,
                searchCategory: selectedCategory,
                searchKeyword: localKeyword,
              })
            }
            type="submit"
            isLoading={loadingSearch}
            loadingLabel=""
            variant="secondary"
          >
            {t('search_bar.search')}
          </Button>
        </MobileButtonContainer>
      )}
    </>
  )
}
